@import '../../assets/variable';

.messageContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 101;
  .blackBg {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: background-color 0.1s;
    z-index: 0;
  }
  .childrenContainer {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    transition: transform 0.2s ease-out;
    padding: 20px;
    .children {
      background-color: $gray50;
      padding: 15px;
      border-radius: 4px;
      box-shadow: 0px 3px 5px -1px rgba($color: #000000, $alpha: 0.2),
        0px 6px 10px 0px rgba($color: #000000, $alpha: 0.14),
        0px 1px 18px 0px rgba($color: #000000, $alpha: 0.12);
      align-items: center;
      & > p {
        color: white;
        font-size: 18px;
        font-weight: 300;
      }
      .SVGIcon {
        $size: 30px;
        min-width: $size;
        min-height: $size;
        max-width: $size;
        max-height: $size;
        fill: white;
        margin-left: 10px;
        background-color: rgba($color: #000000, $alpha: 0.2);
        &:hover {
          background-color: rgba($color: $purple0, $alpha: 0.2);
        }
      }
    }
  }
  &.open {
    .blackBg {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  &.close {
    .blackBg {
      background-color: transparent;
    }
    animation: setVisibility 0.2s linear 0s forwards;
    @keyframes setVisibility {
      to {
        visibility: hidden;
      }
    }
  }

  &.directionTop.open {
    .childrenContainer {
      transform: none;
      top: 0;
    }
  }

  &.directionTop.close {
    .childrenContainer {
      transform: translateY(-100%);
      top: 0;
    }
  }

  &.directionBottom.open {
    .childrenContainer {
      transform: none;
      bottom: 0;
    }
  }

  &.directionBottom.close {
    .childrenContainer {
      transform: translateY(100%);
      bottom: 0;
    }
  }
}
