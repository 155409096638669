@import '../../../assets/variable';

.personOnTrainContainer {
    position: absolute;
    bottom: 0px;
    margin-bottom: -1px;
}


.personOnTrainContainer.tabletMode {
    transform: scale(0.7);
    margin-bottom: -23px;
}

.personOnTrainContainer.mobileMode {
    transform: scale(0.8);
    margin-bottom: -16px;
}