@import '../../../../assets/variable';

.skillsContainer {
  position: absolute;
  bottom: 0;
  height: 100%;
  .skillHanger {
    position: absolute;
    align-items: center;
    height: 10%;
    transform-origin: center top;
    top: -100%;
    .rope {
      width: 1px;
      background-color: $gray40;
    }
    &.runAnimation {
      animation: moveDown 0.6s ease-out 0s forwards,
      swing2Deg 2s ease-in-out 0s infinite alternate;
      @keyframes moveDown {
        to {
          top: 0;
        }
      }
    }
  }
}

.skillsContainer.tabletMode {
  .skillHanger {
    .pill {
      font-size: 12px;
      padding: 5px 10px 5px 10px;
    }
  }
}

.skillsContainer.mobileMode {
}
