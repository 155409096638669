@import '../../../../assets/variable';

.blockContainer {
  position: absolute;
  bottom: 0;
  .blockType_Normal {
    width: 200px;
    height: 95px;
    background-color: gray;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-image: $svg-line;
  }
  .block_cyan {
    background-color: $cyan10;
    @include platformTopShadow($cyan20, true);
  }
  .block_wood {
    background-color: $wood0;
    background-image: url('../../../../assets/img/soil.png');
    @include platformTopShadow($wood10, true);
  }
  .block_cement{
    background-color: $gray15;
    @include platformTopShadow($gray18, true);
  }
  .block_cement_type2{
    background: url('../../../../assets/img/grayGround.png');
    @include platformTopShadow($gray10, true);
  }
  .blockType_Sea {
    width: $waterPlatFormWidth + 200px;
    height: 60px;
    .seaBlock {
      position: absolute;
      width: 400px;
      height: 100%;
      box-shadow: inset 0px 10px 0px 0px $wood0;
      &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 10px;
        background-color: $wood0;
        left: 10%;
      }
      &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 10px;
        background-color: $wood0;
        right: 10%;
      }
      filter: drop-shadow(-3px 3px 2px #00000060);
      &:last-child {
        right: 0;
      }
    }
  }
}

.blockContainer.tabletMode {
  .blockType_Normal {
    width: 180px;
    height: 70px;
  }
}

.blockContainer.mobileMode {
  .blockType_Normal {
    width: 170px;
    height: 70px;
  }
}

