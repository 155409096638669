@import '../../assets/variable';

.pulleyContainer {
  width: 35px;
  height: 35px;
  background-color: $gray30;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .circle {
    width: 15px;
    height: 15px;
    background-color: $gray40;
    border-radius: 50%;
  }
  &::after {
    position: absolute;
    content: '';
    width: 10px;
    height: 20px;
    background-color: $gray40;
    top: 20px;
  }
  &::before {
    position: absolute;
    content: '';
    width: 60px;
    height: 10px;
    background-color: $gray40;
    top: 40px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
}
