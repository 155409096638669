@import '../../../../assets/variable';

$deskTopWidth: 150px;
.employmentHistoryContainer {
  height: 100%;
  // width: 800px;
  position: absolute;
  // background-color: rgba(255, 0, 0, 0.315);
  .employmentElementContainer {
    position: absolute;
    width: $deskTopWidth;
    height: 90%;
    align-items: center;
    top: -100%;
    margin-top: -5px;
    transform-origin: center top;
    &.moveDown {
      animation: moveDown 1s ease-out 0s forwards,
      swing2Deg 2s ease-in-out 0s infinite alternate;
      @keyframes moveDown {
        to {
          top: 0;
        }
      }
    }
    .chain {
      width: 25px;
      border-left: 2px solid $gray40;
      border-right: 2px solid $gray40;
      position: relative;

      & > p {
        transform: rotateZ(-90deg);
        position: absolute;
        top: 180px;
        white-space: nowrap;
        transform-origin: left top;
        background-color: $gray30;
        color: $gray0;
        padding: 0px 10px 0px 10px;
        height: 21px;
      }
    }
    .cabinet {
      width: $deskTopWidth;
      height: $deskTopWidth;
      width: 100%;
      background-color: $gray40;
      border: 10px solid $gray30;
      border-radius: 4px;
      text-align: left;
      box-shadow: inset 0px 0px 3px 0px black;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      padding: 5px;
      p {
        color: $gray0;
        font-size: 13px;
        font-weight: lighter;
      }
      .organisation {
        font-weight: bold;
        color: $gray0;
        font-size: 14px;
      }
      .period {
        font-size: 12px;
        &.active {
          color: $yellow10;
        }
      }
    }
  }
}

$tabletWidth: 130px;
.employmentHistoryContainer.tabletMode {
  .employmentElementContainer {
    width: $tabletWidth;
    height: 95%;
    .chain {
      & > p {
        display: none;
      }
    }
    .cabinet {
      width: $tabletWidth;
      height: $tabletWidth;
      p {
        font-size: 12px;
      }
    }
  }
}

$mobileWidth: 140px;
.employmentHistoryContainer.mobileMode {
  .employmentElementContainer {
    width: $mobileWidth;
    height: 85%;
    .cabinet {
      width: $mobileWidth;
      height:$mobileWidth;
    }
  }
}
