@import '../../../../assets/variable';

.skillComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 15px 10px 15px;
    transition: transform 0.1s;
    .iconAvatar{
        position: relative;
        background-repeat: no-repeat;
        width: 50px;
        height: 50px;
        background-position: -84px -11px;
    }
    .skillLabel {
        font-size: 13px;
    }
    &:hover {
        transform: scale(1.1);
        .skillLabel {
            font-weight: 500;
        }
    }
}

.skillComponent.tabletMode {
    padding: 10px;
}

.skillComponent.mobileMode {
    padding: 8px;
    .skillLabel {
        font-size: 11px;
    }
}

[data-theme='dark'] {
    .skillComponent {
        .skillLabel{
            color: white;
        }
    }
  }
