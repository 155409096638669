@import '../../assets/variable';

.sideNavMenuListContainer {
  .menuList {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 10px;
    li {
      .linkContainer {
        text-decoration: none;
        display: block;
        width: 100%;
        height: 100%;
        padding: 16px;
        box-sizing: border-box;
        @extend .lightModeFocus;
      }
      .button {
        display: block;
        text-align: left;
        background: transparent;
        padding: 16px;
        margin: 0;
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: inherit;
        font-family: inherit;
        @extend .lightModeFocus;
      }
    }
    li.lineBreak {
      height: 1px;
      background-color: rgba($color: black, $alpha: 0.15);
      margin: 10px 0;
    }
    li:not(.lineBreak) {
      white-space: nowrap;
      margin-top: 5px;
      transition: background-color 0.2s;
      cursor: pointer;
      .menuIcon {
        width: 24px;
        height: 24px;
        vertical-align: middle;
        fill: rgba(0, 0, 0, 0.5);
      }
      .menuLabel {
        vertical-align: middle;
        margin-left: 20px;
        color: rgba(0, 0, 0, 0.8);
        cursor: pointer;
      }
      &:hover,
      & > a.active {
        background-color: rgba($color: black, $alpha: 0.05);
      }
    }
  }
}

.sideNavMenuListContainer.isDark {
  .menuList {
    li.lineBreak {
      background-color: rgba($color: white, $alpha: 0.15);
    }
    li:not(.lineBreak) {
      .menuIcon,
      .menuLabel {
        fill: white;
        color: white;
      }
      &:hover,
      & > .linkContainer.active {
        background-color: rgba(255, 255, 255, 0.08);
      }
      .linkContainer,.button {
        @extend .darkModeFocus;
      }
    }
  }
}