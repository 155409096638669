@import '../../../../assets/variable';

.aboutMeContainer {
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 0;
  width: 400px;
  height: 100%;
  padding-left: 100px;
}

.aboutMeContainer.tabletMode {
}

.aboutMeContainer.mobileMode {
}
