@import '../../../assets/variable';

.platformContainer {
  background-color: $gray10;
  .platform {
    height: 100%;
    float: left;
  }
  .aboutMePlatForm {
    background-image: $svg-line;
    width: $aboutMePlatFormWidth;
    background-color: $cyan10;
    @include platformTopShadow($cyan20);
  }
  .waterPlatForm {
    width: $waterPlatFormWidth;
    animation: wave 100s infinite linear,
      waveUpDown 2s infinite linear alternate;
    background-size: 30px;
  }
  .higherEducationPlatForm {
    background-color: $wood0;
    width: $higherEducationPlatFormWidth;
    background-repeat: repeat-x,repeat;
  }
  .railwayPlatForm {
    width: $railwayPlatFormWidth;
    background-size: 25px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      background-image: $svg_track;
      top: 0;
      left: 0;
      width: 100%;
      height: 10px;
      background-repeat: repeat-x;
      background-size: 35px;
    }
  }

  .graduationPlatForm {
    background-image: $svg-line;
    background-color: $gray15;
    width: $graduationPlatFormWidth;
    @include platformTopShadow($gray18);
  }

  .plainRoadPlatForm{
    background-color: $gray20;
    width: $carRoadPlatFormWidth;
    @include platformTopShadow($gray30);
    position: relative;
    &::before{
      content: '';
      width: 100%;
      position: absolute;
      top: 4px;
      border-top: 1px dashed #ffffff36;
    }
  }

  .workProfilePlatForm {
    background-image: $svg-line;
    width: $workProfilePlatFormWidth;
    @include platformTopShadow($gray10);
  }
  @keyframes wave {
    from {
      background-position-x: 0;
    }
    to {
      background-position-x: 100%;
    }
  }
  @keyframes waveUpDown {
    from {
      background-position-y: -5px;
    }
    to {
      background-position-y: 5px;
    }
  }
}

.platformContainer.tabletMode {
}

.platformContainer.mobileMode {
}
