@import '../../../assets/variable';

.sunContainer {
  .sun {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    right: 10%;
    top: 10%;
    transition: box-shadow 5s linear 1s;
    li {
      position: absolute;
      list-style: none;
      background: #737277;
      border-radius: 50%;
      transition: opacity 5s;
    }
    li:nth-child(1) {
      left: 10px;
      top: 30px;
      width: 25px;
      height: 25px;
      box-shadow: inset 6px -2px 0 0px #414043;
    }
    li:nth-child(2) {
      left: 50px;
      top: 20px;
      width: 10px;
      height: 10px;
      box-shadow: inset 3px -1px 0 0px #414043;
    }

    li:nth-child(3) {
      left: 70px;
      top: 35px;
      width: 18px;
      height: 18px;
      box-shadow: inset 3px -1px 0 0px #414043;
    }

    li:nth-child(4) {
      left: 60px;
      top: 60px;
      width: 8px;
      height: 8px;
      box-shadow: inset 3px -1px 0 0px #414043;
    }

    li:nth-child(5) {
      left: 30px;
      top: 70px;
      width: 16px;
      height: 16px;
      box-shadow: inset 3px -1px 0 0px #414043;
    }
  }
  .day {
    background-color: white;
    box-shadow: 0px 0px 10px 3px white, inset 0px 0px 10px 0px #ffb8b870;
    li {
      opacity: 0;
    }
  }
  .night {
    box-shadow: 0px 0px 10px 0px #c7cbd0, inset -25px 0px 0 0px #9098a1;
    li {
      opacity: 1;
    }
  }
}

.sunContainer.tabletMode {
  .sun {
    transform: scale(0.9);
  }
}

.sunContainer.mobileMode {
  .sun {
    transform: scale(0.8);
  }
}
