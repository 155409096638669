@import '../../../../../assets/variable';

$globeWidth: 150px;

.globeContainer {
  width: $globeWidth;
  height: 80%;
  align-items: center;
  position: absolute;
  top: -100%;
  margin-top: -5px;
  transform-origin: center top;
  &.moveDown {
    animation: moveDown 1s ease-out 0s forwards,
      swing 2s ease-in-out 0s infinite alternate;
    @keyframes moveDown {
      to {
        top: 0;
      }
    }
    @keyframes swing {
      from {
        transform: rotateZ(-5deg);
      }
      to {
        transform: rotateZ(5deg);
      }
    }
  }
  .chain {
    width: 25px;
    border-left: 2px solid $gray40;
    border-right: 2px solid $gray40;
    border-bottom: 10px solid $gray40;
    position: relative;
    & > p {
      transform: rotateZ(-90deg);
      position: absolute;
      top: 120px;
      white-space: nowrap;
      transform-origin: left top;
      background-color: $gray30;
      color: $gray0;
      padding: 0px 10px 0px 10px;
      height: 21px;
    }
  }
  .globe {
    width: $globeWidth;
    height: $globeWidth;
    border-radius: 50%;
    background-color: $gray30;
    box-shadow: inset 5px -6px 9px 0px #00000087, -1px 2px 3px 0px #00000087;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    top: -5px;
    &.globeAnim {
      animation: indiaZoom 4s ease-in-out 0.7s forwards;
      @keyframes indiaZoom {
        to {
          transform: rotateZ(360deg);
          background-size: 250% 270%;
          background-position: 0% 80%;
        }
      }
    }
  }
}

$globeWidthTablet: 130px;
.globeContainer.tabletMode {
  height: 95%;
  width: $globeWidthTablet;
  .chain {
    & > p {
      display: none;
    }
  }
  .globe {
    width: $globeWidthTablet;
    height: $globeWidthTablet;
  }
}

.globeContainer.mobileMode {
}
