@import './assets/variable';

.appContainer {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.shareButtonsContainer {
  padding: 30px 20px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .shareButtons {
    opacity: 0.9;
    transition: filter 0.2s,opacity 0.2s;
    &:hover {
      opacity: 1;
      filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.397));
    }
  }
  .copyIcon {
    box-sizing: border-box;
    cursor: pointer;
    width: 50px;
    height: 50px;
    padding: 10px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.4);
    fill: white;
  }
}

.fullScreenCloseButton {
  width: 30px;
  height: 30px;
  position: fixed;
  z-index: 99;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
  fill: white;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 0.7;
  @extend .SVGIcon;
  &:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.3);
  }
}
