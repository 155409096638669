@import '../../../../assets/variable';

.carContainer {
  width: 300px;
  height: 90px;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
  .tiers {
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    animation: runCarAnim 0.8s linear 0s infinite;
  }
  .leftTier {
    bottom: 5px;
    left: 39px;
  }
  .rightTier {
    bottom: 5px;
    right: 59px;
  }
  .light {
    height: 50%;
    width: 100%;
    position: absolute;
    background: linear-gradient(to left, rgba(247, 168, 60, 0) 0%, rgba(255, 255, 255, 0.5) 100%);
    clip-path: polygon(0 0, 100% 100%, 40% 100%, 0 0);
    left: 100%;
    top: 51%;
    margin-left: -13px;
  }
}

.carContainer.tabletMode {
  &.enableScale {
    transform: scale(0.7);
    &.rotateBack {
      transform: scale(0.7) rotateY(180deg);
    }
    margin-bottom: -13px;
    &.positionLeft {
      margin-left: -48px;
    }
    &.positionRight {
      margin-left: 37px;
    }
  }
}

.carContainer.mobileMode {
  &.enableScale {
    transform: scale(0.8);
    &.rotateBack {
      transform: scale(0.8) rotateY(180deg);
    }
    margin-bottom: -9px;
    &.positionLeft {
      margin-left: -35px;
    }
    &.positionRight {
      margin-left: 25px;
    }
  }
}

@keyframes runCarAnim {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
