@import '../../assets/variable';

.root {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.rounded {
  border-radius: 5px;
}

.rectangle {
  border-radius: 0px;
}

.small {
  font-size: 12px;
  line-height: 16px;
  padding: 5px;
  min-width: 15px;
}

.normal {
  font-size: 14px;
  line-height: 20px;
  padding: 5px 20px 5px 20px;
  min-width: 20px;
}

.grey {
  background-color: $gray20;
  color: white;
}

.orange {
  background-color: orange;
  color: black;
}

.red {
  background-color: red;
  color: white;
}

.green {
  background-color: $cyan0;
  color: black;
}

.black {
  background-color: black;
  color: white;
}

.blue {
  background-color: $blue10;
  color: white;
}

.cyan {
  background-color: $cyan10;
  color: white;
}
