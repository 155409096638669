@import '../../../assets/variable';

.cloudContainer {
  padding: 10px;
  .cloudBox {
    width: 250px;
    height: 180px;
    position: relative;
    float: left;
    margin-right: 400px;
    transform: scale(0.8);
    margin-top: -30px;
    .cloud {
      background-color: #fff;
      transition: background-color 5s;
      border-radius: 100px;
      box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
      height: 100px;
      position: absolute;
      width: 100%;
      bottom: 0;

      &::after,
      &::before {
        background-color: #fff;
        content: '';
        position: absolute;
        transition: background-color 5s;
      }

      &::after {
        border-radius: 50%;
        height: 90px;
        width: 90px;
        left: 44px;
        top: -40px;
      }

      &::before {
        border-radius: 50%;
        width: 115px;
        height: 116px;
        right: 32px;
        top: -65px;
      }
    }
    @keyframes animateCloud {
      0% {
        margin-left: -320px;
      }
      100% {
        margin-left: 100%;
      }
    }
  }
}

.cloudContainer.tabletMode {
  .cloudBox {
    transform: scale(0.7);
  }
}

.cloudContainer.mobileMode {
  .cloudBox {
    transform: scale(0.6);
  }
}
