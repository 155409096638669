@import '../../../assets/variable';
@use "sass:list";

$mountainUrl: url('../../../assets/img/mountain.png');
$tree1SVG: url('../../../assets/svg/tree1.svg');
$tree2SVG: url('../../../assets/svg/tree2.svg');
$tree3SVG: url('../../../assets/svg/tree3.svg');
$tree4SVG: url('../../../assets/svg/tree4.svg');
$tree5SVG: url('../../../assets/svg/tree5.svg');
$craneUrl: url('../../../assets/img/crane_type1.png');
$city1Url: url('../../../assets/img/city.png');
$city2Url: url('../../../assets/img/city2.png');
$city3Url: url('../../../assets/img/city3.png');

@function aboutMeCranesImg($type: $TypeImg) {
  @if $type == $TypeImg {
    @return $craneUrl, $craneUrl;
  } @else if $type == $TypePosition {
    @return 1566px bottom, 1947px bottom;
  } @else if $type == $TypeRepeat {
    @return repeat-y, repeat-y;
  } @else if $type == $TypeSize {
    @return 45px, 45px;
  }
}

@function treeImg($type: $TypeImg) {
  @if $type == $TypeImg {
    @return $tree4SVG, $tree2SVG, $tree3SVG, $tree5SVG;
  } @else if $type == $TypePosition {
    @return 20px bottom, 190px bottom, 600px bottom, 690px bottom;
  } @else if $type == $TypeRepeat {
    @return no-repeat, no-repeat, no-repeat, no-repeat;
  } @else if $type == $TypeSize {
    @return 90px, 80px, 100px, 60px;
  }
}

@function mountainImg($type: $TypeImg) {
  @if $type == $TypeImg {
    @return $mountainUrl, $mountainUrl, $mountainUrl, $mountainUrl, $mountainUrl;
  } @else if $type == $TypePosition {
    @return 200px bottom, 1200px bottom, 1400px bottom, 2500px bottom,
      3000px bottom;
  } @else if $type == $TypeRepeat {
    @return no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
  } @else if $type == $TypeSize {
    @return 600px 300px, 400px 200px, 420px 210px, 420px 210px, 420px 210px;
  }
}

@function citiesImg($type: $TypeImg) {
  @if $type == $TypeImg {
    @return $city1Url, $city1Url, $city1Url, $city2Url, $city3Url, $city2Url,
      $city3Url, $city2Url, $city3Url,$city2Url;
  } @else if $type == $TypePosition {
    @return 8900px bottom, 9410px bottom, 9930px bottom, 11000px bottom,
      11600px bottom, 12200px bottom, 13200px bottom, 13800px bottom,
      14900px bottom,15500px bottom;
  } @else if $type == $TypeRepeat {
    @return no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat,
      no-repeat, no-repeat, no-repeat,no-repeat;
  } @else if $type == $TypeSize {
    @return 502px 175px, 502px 175px, 502px 175px, 1110px 175px, 593px 143px,
      1110px 175px, 593px 143px, 1110px 175px, 593px 143px,1110px 175px;
  }
}

.mountainsAndCityContainer {
  .backgroundImages {
    background-image: aboutMeCranesImg(), treeImg(), mountainImg(), citiesImg();
    background-position: aboutMeCranesImg($TypePosition), treeImg($TypePosition),
      mountainImg($TypePosition), citiesImg($TypePosition);
    background-repeat: aboutMeCranesImg($TypeRepeat), treeImg($TypeRepeat),
      mountainImg($TypeRepeat), citiesImg($TypeRepeat);
    background-size: aboutMeCranesImg($TypeSize), treeImg($TypeSize),
      mountainImg($TypeSize), citiesImg($TypeSize);
  }
}

.mountainsAndCityContainer.tabletMode {
  @function mountainImg($type: $TypeImg) {
    @if $type == $TypeImg {
      @return $mountainUrl, $mountainUrl, $mountainUrl, $mountainUrl,
        $mountainUrl;
    } @else if $type == $TypePosition {
      @return 200px bottom, 1200px bottom, 1400px bottom, 2500px bottom,
        3000px bottom;
    } @else if $type == $TypeRepeat {
      @return no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    } @else if $type == $TypeSize {
      @return 400px 200px, 350px 175px, 400px 200px, 420px 210px, 420px 210px;
    }
  }
  @function treeImg($type: $TypeImg) {
    @if $type == $TypeImg {
      @return $tree4SVG, $tree2SVG, $tree3SVG, $tree5SVG;
    } @else if $type == $TypePosition {
      @return 50px bottom, 150px bottom, 550px bottom, 600px bottom;
    } @else if $type == $TypeRepeat {
      @return no-repeat, no-repeat, no-repeat, no-repeat;
    } @else if $type == $TypeSize {
      @return 50px, 45px, 60px, 45px;
    }
  }

  .backgroundImages {
    background-size: aboutMeCranesImg($TypeSize), treeImg($TypeSize),
      mountainImg($TypeSize), citiesImg($TypeSize);
    background-position: aboutMeCranesImg($TypePosition), treeImg($TypePosition),
      mountainImg($TypePosition), citiesImg($TypePosition);
  }
}

.mountainsAndCityContainer.mobileMode {
}
