@import "../../../../assets/variable";

.graduationContainer {
  position: absolute;
  width: 400px; 
  height: 250px;
  bottom: 0;
  .buildingImg {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center bottom;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    &.runBuildingAnim {
      animation: moveFromBottom 0.3s ease-out forwards;
      @keyframes moveFromBottom {
        0% {
          bottom: -100%;
        } 25% {
          opacity: 0.7;
        } 90% {
          bottom: 20%;
        } 100% {
          opacity: 1;
          bottom: 0;
        }
      }
    }
  }
}

.graduationContainer.tabletMode {
  width: 300px; 
  height: 210px;
}

.graduationContainer.mobileMode {
  width: 300px; 
  height: 230px;
}
