@import '../../../assets/variable';

.mobileNavigationContainer {
  .navigations {
    height: 100%;
    padding-top: 10px;
    .right,
    .middle,
    .left {
      & > div {
        background: rgba($color: #000000, $alpha: 0.2);
        background-clip: content-box;
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        pointer-events: none;
        p {
          padding: 5px;
          font-weight: bold;
          text-align: center;
          color: white;
          animation: flash 1s linear infinite;
          @keyframes flash {
            50% {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}

.mobileNavigationContainer.tabletMode {
}

.mobileNavigationContainer.mobileMode {
}

@media (any-hover: none) {
  .navigations {
    .middle {
      // border-left:2px solid #00000075;
      // border-right:2px solid #00000075;
    }
  }
}
