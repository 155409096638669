@import './interactive-resume-variables';

$gray0: #e6e9ed;
$gray5: #cbd0d0;
$gray10: #9e9e9e;
$gray15: #727e8a;
$gray16: #717171;
$gray18: #5d666e;
$gray20: #636060;
$gray30: #494848;
$gray31: #424242;
$gray35: #42494d;
$gray40: #33373a;
$gray50: #202124;

$blue0: #3fd2fa;
$blue10: #4a89dc;
$blue20: #3977ca;

$cyan0: #37bc9b;
$cyan10: #009688;
$cyan20: #03786d;
$cyan50: #155260;
$cynan60: #08998c;
$tiber30: #073531;

$wood0: #c69c6d;
$wood10: #e7dfc2;
$wood20: #b5997a;
$wood30: #9a846b;
$red0: #b4311a;
$red5: #e1002d;
$red10: #ad3f38;
$red20: #8c322d;

$yellow0: #f7a83c;
$yellow10: #ffa726;
$yellow20: #ff9800;
$orange0: #e25c45;

$ruby0: #e31b6d;
$purple0: #7e6dd1;

$skyColor1: $blue0;
$skyColor2: #32a6c6;
$skyColor3: #1b596a; //#074e61;
$skyColor4: #0c272f;

:export {
  blue0: $blue0;
  wood20: $wood20;
  wood30: $wood30;
  red0: $red0;
  red10: $red10;
  red20: $red20;
  gray0: $gray0;
  gray5: $gray5;
  gray15: $gray15;
  gray18: $gray18;
  gray20: $gray20;
  gray30: $gray30;
  gray35: $gray35;
  gray40: $gray40;
  skyColor1: $skyColor1;
  skyColor2: $skyColor2;
  skyColor3: $skyColor3;
  skyColor4: $skyColor4;
  windowReelSize: $windowReelSize;
}

$mountainColor: #c69c6d;
$moundtainShadowColor: #8c6239;

$desktopPaddingLeftRight: 40px;
$tabletPaddingLeftRight: 30px;
$mobilePaddingLeftRight: 20px;
$box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);

@mixin card(
  $width: 100%,
  $height: auto,
  $minWidth: 0,
  $minHeight: 0,
  $hover: false
) {
  width: $width;
  height: $height;
  min-width: $minWidth;
  min-height: $minHeight;
  box-shadow: $box-shadow;
  transition: box-shadow 0.3s;
  border-radius: 5px;
  @if $hover {
    &:hover {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }
  }
}

@mixin button($fontcolor: $gray20, $bg: $gray0, $hoverbg: #cfd0d2) {
  background-color: $bg;
  border: none;
  padding: 8px 20px 8px 20px;
  border-radius: 4px;
  color: $fontcolor;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  text-decoration: none;
  display: inline-block;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  &[href] {
    font-size: 13px;
    vertical-align: middle;
    padding-top: 7px;
  }
  &:hover {
    background-color: $hoverbg;
    transform: translate(0px, -1px);
  }
  &:active {
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
  }
}

@mixin timelineCard(
  $timelineColor: $blue10,
  $cardPadding: 32px,
  $cardLeftSpace: 32px,
  $spaceBetweenCards: 32px
) {
  border-left: 2px solid $timelineColor;
  padding: 20px 0 20px $cardLeftSpace;
  .card {
    border: 1px solid $gray0;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    border-radius: 4px;
    border-left-color: $timelineColor;
    border-left-width: 2px;
    padding: $cardPadding;
    margin-top: $spaceBetweenCards;
    position: relative;
    // line
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      width: $cardLeftSpace;
      margin-left: -1px;
      height: 2px;
      top: 25px;
      left: -1 * $cardLeftSpace;
      z-index: 1;
      background-color: $timelineColor;
    }
    // Cicle
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      background-color: #fff;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      top: 19px;
      left: -1 * $cardLeftSpace;
      margin-left: -10px;
      border: 2px solid $timelineColor;
      z-index: 2;
    }
  }
}

.colorTransition {
  $time: 0.5s;
  transition: background-color $time !important;
}

.SVGIcon {
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.3s, opacity 0.2s;
  cursor: pointer;
  &:hover, &:focus {
    background-color: rgba(0, 0, 0, 0.1);
    outline: none;
  }
}

$TypeImg: 'TypeImg';
$TypePosition: 'TypePosition';
$TypeSize: 'TypeSize';
$TypeRepeat: 'TypeRepeat';

.outlineBtn {
  margin: 0;
  padding: 8px 16px;
  cursor: pointer;
  background-color: transparent;
  border: 2px solid white;
  color: white;
  font-size: 16px;
  transition: background-color .2s, border-color .2s, color .2s;
  border-radius: 1px;
  text-decoration: none;
  display: flex;
  align-items: center;
  svg {
    fill: white;
    margin-right: 5px;
    transition: fill .2s;
  }
  &:hover {
    background-color: $purple0;
    border-color: $purple0;
    outline: none;
  }
  &:focus-visible {
    outline: 1px solid white;
    outline-offset: 5px;
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
}

@keyframes shake {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-10deg); }
  50% { transform: rotate(10deg); }
  75% { transform: rotate(-10deg); }
  100% { transform: rotate(0deg); }
}

@keyframes flash {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}