@import '../../assets/variable';

.plainResumeContainer {
  height: 100%;
  overflow: auto;
  padding-bottom: 50px;
  @extend .colorTransition;
  .divider {
    border-bottom: 1px solid $gray10;
  }

  .centerDiv {
    width: 80%;
    max-width: 1036px;
    margin: 0 auto;
    .navigation,
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0 20px 0;
      .name {
        color: $gray20;
        font-weight: 300;
        margin: 0;
        font-size: 35px;
      }
      .headerIcons {
        ul,
        li {
          list-style: none;
          text-decoration: none;
          padding: 0;
          float: left;
          &:not(:first-child) {
            margin-left: 5px;
          }
          .SVGIconLink {
            text-decoration: none;
            display: inline-block;
            font-size: 0;
            @extend .lightModeFocus;
          }
          .iconBtnContainer {
            display: inline-block;
            background: transparent;
            padding: 0;
            margin: 0;
            width: auto;
            height: auto;
            font-size: 0;
            border: none;
            text-align: center;
            @extend .lightModeFocus;
          }
          .SVGIcon {
            width: 35px;
            height: 35px;
            fill: $gray10;
          }
        }
      }
    }

    .footer {
      flex-direction: column;
      margin-top: 40px;
      .copyRight {
        color: $gray20;
        font-weight: 400;
      }
    }

    .resume {
      padding-bottom: 20px;
      overflow: hidden;
      @include card(100%, auto, 0, auto);
      @extend .colorTransition;
    }
  }
}

.tabletMode {
  .centerDiv {
    width: 90%;
    .resume {
      .main {
        // padding: 0 $tabletPaddingLeftRight 0 $tabletPaddingLeftRight;
      }
    }
  }
}

.mobileMode {
  .centerDiv {
    width: 95%;
    .navigation {
      flex-direction: column;
    }
    .resume {
      .main {
        // padding: 0 $mobilePaddingLeftRight 0 $mobilePaddingLeftRight;
      }
    }
  }
  :global {
    .resumeHeading1 {
      font-size: 22px;
    }
    .resumeHeading2 {
      font-size: 16px;
    }
  }
}

[data-theme='dark'] {
  .plainResumeContainer {
    background-color: $gray50;
    .centerDiv {
      .resume {
        background-color: $gray40;
      }
      .navigation,
      .footer {
        .name,
        .copyRight,
        .SVGIcon {
          color: white;
          fill: white !important;
        }
        .headerIcons {
          li {
            .SVGIconLink,.iconBtnContainer {
              @extend .darkModeFocus;
            }
            .SVGIcon {
              &:hover {
                background-color: rgba($color: $purple0, $alpha: 0.2) !important;
              }
            }
          }
        }
        
      }
    }
  }
}
