@import './assets/variable';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}

div {
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

#root {
  height: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.fadeInAnimation {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  position: relative;
  opacity: 0;
  animation-timing-function: ease-in-out;
}

@keyframes fadeInFromRight {
  0% {
    opacity: 0;
    left: 30px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes fadeInFromLeft {
  0% {
    opacity: 0;
    left: -30px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes fadeInFromBottom {
  0% {
    opacity: 0;
    top: 30px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes fadeInFromTop {
  0% {
    opacity: 0;
    top: -30px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

.resumeHeading1 {
  color: $cynan60;
  font-size: 28px;
  font-weight: 500;
  padding-bottom: 20px;
  @extend .colorTransition;
}

.resumeHeading2 {
  color: $gray20;
  font-size: 18px;
  font-weight: 400;
  @extend .colorTransition;
}

.resumeHeading3 {
  color: $gray20;
  font-size: 13px;
  font-weight: 400;
  @extend .colorTransition;
}

[data-theme='dark'] {
  .resumeHeading1,
  .resumeHeading2,
  .resumeHeading3 {
    color: $gray5 !important;
  }
}

.flex-row,
.flex-col {
  display: flex;
  height: 100%;
}
.flex-col {
  flex-direction: column;
}

.size-1 {
  flex: 1 1 8.33%;
}
.size-2 {
  flex: 1 1 16.66%;
}
.size-3 {
  flex: 1 1 25%;
}
.size-4 {
  flex: 1 1 33.33%;
}
.size-5 {
  flex: 1 1 41.66%;
}
.size-6 {
  flex: 1 1 50%;
}
.size-7 {
  flex: 1 1 58.33%;
}
.size-8 {
  flex: 1 1 66.66%;
}
.size-9 {
  flex: 1 1 75%;
}
.size-10 {
  flex: 1 1 83.33%;
}
.size-11 {
  flex: 1 1 91.66%;
}
.size-12 {
  flex: 1 1 100%;
}
.size-auto {
  flex: 1;
}

.mobile {
  .flex-row.supportMobileFlex {
    flex-direction: column;
  }
  .flex-row.supportMobileFlex > [class*='size-'] {
    flex: 100%;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 2;
    bottom: 90%;
    left: 47%;
    margin-left: -60px;
    box-shadow: 0px 1px 3px 0px #00000070;
    font-size: 13px;
    &.bottom {
      top: 150%;
      bottom: initial;
    }
    &.right {
      top: 0px;
      left: 110%;
      bottom: initial;
      margin-left: initial;
    }
    &.left {
      top: 0px;
      right: 110%;
      left: initial;
      bottom: initial;
      margin-left: initial;
    }
    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
    &.bottom::after {
      top: initial;
      bottom: 100%;
      left: 50%;
      border-color: transparent transparent black transparent;
    }
    &.right::after {
      top: 50%;
      right: 100%;
      left: initial;
      margin-top: -5px;
      margin-left: initial;
      border-color: transparent black transparent transparent;
    }
    &.left::after {
      top: 50%;
      left: 100%;
      margin-top: -5px;
      margin-left: initial;
      border-color: transparent transparent transparent black;
    }
  }
  &:hover .tooltiptext {
    visibility: visible;
  }
}

.scrollBar {
  overflow: auto !important;
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
