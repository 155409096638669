@import '../../../../assets/variable';

.projectsContainer {
  position: absolute;
  bottom: 0;
  height: 100%;
  .project {
    width: 400px;
    height: 250px;
    background-color: $gray20;
    border-top: 25px solid $gray10;
    padding: 0 40px 0 40px;
    position: absolute;
    bottom: 0;
    .title {
      position: absolute;
      color: $gray40;
      text-align: center;
      width: 100%;
      left: 0;
      transform: translateY(-110%);
    }
    .windowContainer {
      padding: 10px 0 10px 0;
      .window {
        height: 100%;
        background-color: $gray10;
        justify-content: space-evenly;
        align-items: center;
        div {
          border-radius: 2px;
          width: 80px;
          height: 32px;
          background-image: url('../../../../assets/img/window.jpg');
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    .body {
      background-color: $gray35;
      box-shadow: inset 0px 0px 5px 1px #0000008c;
      justify-content: space-around;
      padding: 5px;
      text-align: center;
      p,
      a {
        color: $gray10;
      }
      .period {
        font-size: 13px;
      }
      .projectName {
        color: $gray0;
        font-weight: bold;
      }
      .projectOverview {
        font-size: 12px;
        text-align: justify;
      }
    }
  }
}

.projectsContainer.tabletMode {
  .project {
    width: 340px;
    height: 220px;
    padding: 0 30px 0 30px;
  }
}

.projectsContainer.mobileMode {
  .project {
    width: 330px;
    height: 220px;
    padding: 0 35px 0 35px;
    .body {
      .period {
        font-size: 12px;
      }
      .projectName {
        font-size: 13px;
      }
      .projectOverview {
        font-size: 11px;
      }
    }
  }
}
