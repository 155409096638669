@import '../../assets/variable';

.homeContainer {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  background-color: $gray10;
  .homePageSideBarMenuIcon {
    fill: white;
    &:hover {
      background-color: rgba($color: $purple0, $alpha: 0.2) !important;
    }
  }
  .shareButtonIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 100;
    .SVGIcon {
      fill: white;
      width: 35px;
      height: 35px;
      &:hover {
        background-color: rgba($color: $purple0, $alpha: 0.2) !important;
      }
    }
  }

  .interactiveResumeOverviewContainer {
    @extend .absoluteTopLeft;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: $gray0;
    .topBox,
    .bottomBox {
      position: relative;
    }
    .topBox {
      .lightModebackgroundImages,
      .darkModebackgroundImages {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transition: opacity 4s;
      }
      .lightModebackgroundImages {
        background-image: url('../../assets/svg/tree1.svg'),
          url('../../assets/img/mountain.png');
        background-repeat: no-repeat, no-repeat;
        background-size: 256px, 600px 300px;
        background-position: left bottom, center bottom;
      }
      .darkModebackgroundImages {
        background-image: url('../../assets/img/city3.png');
        background-repeat: repeat-x;
        background-size: 592px 143px;
        background-position: center bottom;
      }
    }
    .bottomBox {
      .aboutMePlatForm {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-image: $svg-line;
        width: $aboutMePlatFormWidth;
        background-color: $cyan10;
        @include platformTopShadow($cyan20);
        transition: opacity 4s;
      }
      .workProfilePlatForm {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background-image: $svg-line;
        @include platformTopShadow($gray10);
        transition: opacity 4s;
      }
    }
  }

  .navigationContainer {
    @extend .absoluteTopLeft;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.6);
    z-index: 2;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    .content {
      transform: translateY(-40%);
      h1 {
        font-size: 30px;
        font-weight: 300;
        text-align: center;
        span {
          color: $yellow0;
          font-weight: 400;
        }
      }
      h1 + h1 {
        margin-top: 10px;
      }
      .navBtns {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .outlineBtn {
          margin: 0 5px;
          margin-top: 20px;
        }
        .interactiveResumeBtn {
          border-color: $yellow0;
          color: $yellow0;
          svg {
            fill: $yellow0;
          }
          &:hover {
            background-color: $yellow0;
            color: white;
            svg {
              fill: white;
            }
          }
        }
      }
    }
    .footer {
      position: absolute;
      bottom: 0;
      z-index: 1;
      padding-bottom: 20px;
      .copyRight {
        text-align: center;
        color: white;
        font-size: 15px;
        font-weight: 300;
        b {
          color: $yellow0;
        }
      }
    }
  }
}

.homeContainer.mobileMode {
  .navigationContainer {
    padding: 5px;
    .content {
      h1 {
        font-size: 28px;
      }
      .navBtns {
        .outlineBtn {
          padding: 8px 8px;
        }
      }
    }
  }
  .interactiveResumeOverviewContainer {
    .topBox {
      .lightModebackgroundImages {
        background-size: 150px, 300px 150px;
      }
    }
  }
}

.homeContainer.tabletMode {
  .navigationContainer {
    .footer {
      padding-bottom: 10px;
    }
  }
  .interactiveResumeOverviewContainer {
    .topBox {
      .lightModebackgroundImages {
        background-size: 150px, 400px 200px;
      }
    }
  }
}
