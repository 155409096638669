@import "../../../../assets/variable";

.higherEducationContainer {
  position: absolute;
  width: 930px;
  height: 250px;
  bottom: 0;
  .buildingImg {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center bottom;
    position: relative;
    z-index: 1;
    opacity: 0;
    &.runBuildingAnim {
      animation: moveFromBottom 0.3s ease-out forwards;
      @keyframes moveFromBottom {
        0% {
          bottom: -100%;
        } 25% {
          opacity: 0.7;
        } 90% {
          bottom: 20%;
        } 100% {
          opacity: 1;
          bottom: 0;
        }
      }
    }
  }
  .class10,
  .class12 {
    padding: 20px 20px 0 40px;
    h1 {
      font-size: 16px;
    }
    position: relative;
    opacity: 0;
    &.runBoardAnim {
      animation: moveFromTop 0.1s linear forwards;
      @keyframes moveFromTop {
        from {
          bottom: 30%;
        }
        to {
          opacity: 1;
          bottom: 0;
        }
      }
    }
  }
}

.higherEducationContainer.tabletMode {
  width: 900px;
  height: 210px;
}

.higherEducationContainer.mobileMode {
  width: 850px;
  height: 230px;
}
