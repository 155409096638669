@import '../../../../assets/variable';

.gateContainer {
  width: 300px;
  height: 280px;
  position: absolute;
  bottom: 0;
  .minWidth40 {
    min-width: 40px;
  }
  .heading {
    padding: 5px;
    align-items: center;
    color: white;
    .title {
      text-shadow: -2px 2px 1px #19161285;
      font-size: 40px;
      text-transform: uppercase;
    }
    .subTitle {
      font-size: 20px;
    }
  }
}

.gateContainer.tabletMode {
  width: 220px;
  height: 200px;
  .minWidth40 {
    min-width: 35px;
  }
  .heading {
    .title {
      font-size: 28px;
    }
    .subTitle {
      font-size: 12px;
    }
  }
}

.gateContainer.mobileMode {
  width: 250px;
  height: 230px;
  .minWidth40 {
    min-width: 35px;
  }
  .heading {
    .title {
      font-size: 30px;
    }
    .subTitle {
      font-size: 15px;
    }
  }
}
