@import '../../assets/variable';

$loaderSize: 70px;
$boderWidth: 10px;
.loaderContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .loader {
    border: $boderWidth solid $gray0;
    border-top: $boderWidth solid $ruby0;
    border-radius: 50%;
    width: $loaderSize;
    height: $loaderSize;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
