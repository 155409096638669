@import '../../../assets/variable';

.personOnBoatContainer {
    position: absolute;
    bottom: 0px;
}


.personOnBoatContainer.tabletMode {
    transform: scale(0.7);
    bottom: -39px !important;
}

.personOnBoatContainer.mobileMode {
    transform: scale(0.8);
    bottom: -26px !important;
}