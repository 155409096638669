@import '../../../assets/variable';

.personContainer {
  height: 187px;
  width: 137px;
  position: absolute;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: 0 -224px;
  margin-bottom: -11px;
  z-index: 1;
  transition: bottom 0.3s;
  filter: drop-shadow(#00000080 1px 0px 3px);
  .person {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .speechBoxContainer {
    background: $gray0;
    position: absolute;
    color: black;
    font-size: 17px;
    width: 250px;
    height: 120px;
    border-radius: 10px;
    padding: 20px;
    transform: translate(0, -115%);
    box-shadow: -2px 2px 2px 0px #00000017;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    .pointer {
      animation: blink 0.5s ease-in-out 0s infinite;
      @keyframes blink {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 0;
      z-index: 1;
      border-style: solid;
      border-color: $gray0 transparent;
      border-width: 25px 15px 0;
      bottom: -24px;
      left: 45%;
      margin-left: -10px;
    }
    &.isLeftDirection {
      transform: translate(-50%, -115%);
      &::after {
        left: 50%;
      }
    }
  }
}

.breath {
  background-position-y: -224px !important;
  animation: breathAnimation 0.8s steps(2) infinite !important;
}

@keyframes breathAnimation {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: -262px;
  }
}

.runCharacter {
  background-position-y: -12px !important;
  animation: runCharacterAnim 0.65s steps(8) infinite !important;
}

@keyframes runCharacterAnim {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: -1200px;
  }
}

.personContainer.tabletMode {
  transform: scale(0.7);
  margin-bottom: -38px;
}

.personContainer.mobileMode {
  transform: scale(0.8);
  margin-bottom: -30px;
}

.shadow {
  height: 8px;
  background-color: #0000002e;
  position: absolute;
  border-radius: 50%;
  bottom: 0;
  z-index: 1;
  margin-bottom: -9px;
  transition: transform 0.3s;
  filter: blur(1px);
}
