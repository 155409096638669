@import '../../../assets/variable';

$tree1SVG: url('../../../assets/svg/tree1.svg');
$tree2SVG: url('../../../assets/svg/tree2.svg');
$tree3SVG: url('../../../assets/svg/tree3.svg');
$tree4SVG: url('../../../assets/svg/tree4.svg');
$tree5SVG: url('../../../assets/svg/tree5.svg');
$fence: url('../../../assets/img/fence.png');
$streetLight: url('../../../assets/img/streetLight.png');
$railwaySign: url('../../../assets/img/railwaySign.png');

$trainTreeType1Size: 170px;
$trainTreeType2Size: 200px;
$fenceSize: 300px 90px;
$streetLightSize: 300px;
$railwaySignSize: 112px 260px;


@function loadImages($type: $TypeImg) {
  @if $type == $TypeImg {
    @return $tree1SVG, $tree3SVG,$tree3SVG,
    $fence,$fence,$fence,$fence,$fence,$fence,$fence,
    $tree4SVG,$tree3SVG,$tree4SVG,$tree5SVG,$tree4SVG,$tree2SVG,$tree4SVG,
    $streetLight,$streetLight,
    $railwaySign,$railwaySign;
  } @else if $type == $TypePosition {
    @return 10px bottom, 800px bottom,2900px bottom,
    7700px bottom,8000px bottom,8300px bottom,8600px bottom,8900px bottom,9200px bottom,9400px bottom,
    7700px bottom,8000px bottom,8300px bottom,8600px bottom,8900px bottom,9200px bottom,9500px bottom,
    12450px bottom,15200px bottom,
    7500px bottom,9770px bottom;
  } @else if $type == $TypeRepeat {
    @return no-repeat, no-repeat, no-repeat,
    no-repeat,no-repeat,no-repeat,no-repeat,no-repeat,no-repeat,no-repeat,
    no-repeat,no-repeat,no-repeat,no-repeat,no-repeat,no-repeat,no-repeat,
    no-repeat,no-repeat,
    no-repeat,no-repeat;
  } @else if $type == $TypeSize {
    @return 300px, 250px, 250px,
    $fenceSize,$fenceSize,$fenceSize,$fenceSize,$fenceSize,$fenceSize,$fenceSize,
    $trainTreeType1Size,$trainTreeType2Size,$trainTreeType1Size,$trainTreeType2Size,$trainTreeType1Size,$trainTreeType2Size,$trainTreeType1Size,
    $streetLightSize,$streetLightSize,
    $railwaySignSize,$railwaySignSize;
  }
}
.abovePlatformContainer {
  .backgroundImages {
    background-image: loadImages();
    background-position: loadImages($TypePosition);
    background-repeat: loadImages($TypeRepeat);
    background-size: loadImages($TypeSize);
  }
  .block1 {
    background-color: $cyan10;
    @include platformTopShadow($cyan20, true);
    left: 1000px;
  }
  .trainStationBoardContainer {
    width: 200px;
    height: 200px;
    position: absolute;
    bottom: 0;
    h1 {
      font-size: 16px;
    }
    p {
      font-size: 15px;
    }
  }
}

.abovePlatformContainer.tabletMode {
  $trainTreeType1Size: 150px;
  $trainTreeType2Size: 170px;
  $fenceSize: 300px 70px;
  $streetLightSize: 250px;
  $railwaySignSize: 86px 200px;

  @function loadImages($type: $TypeImg) {
    @if $type == $TypeImg {
      @return $tree1SVG, $tree3SVG,$tree3SVG,
      $fence,$fence,$fence,$fence,$fence,$fence,$fence,
      $tree4SVG,$tree3SVG,$tree4SVG,$tree5SVG,$tree4SVG,$tree2SVG,$tree4SVG,
      $streetLight,$streetLight,
      $railwaySign,$railwaySign;
    } @else if $type == $TypePosition {
      @return  25px bottom, 850px bottom,3000px bottom,
      7700px bottom,8000px bottom,8300px bottom,8600px bottom,8900px bottom,9200px bottom,9400px bottom,
      7700px bottom,8000px bottom,8300px bottom,8600px bottom,8900px bottom,9200px bottom,9500px bottom,
      12450px bottom,15200px bottom,
      7500px bottom,9770px bottom;
    } @else if $type == $TypeRepeat {
      @return no-repeat, no-repeat, no-repeat,
      no-repeat,no-repeat,no-repeat,no-repeat,no-repeat,no-repeat,no-repeat,
      no-repeat,no-repeat,no-repeat,no-repeat,no-repeat,no-repeat,no-repeat,
      no-repeat,no-repeat,
      no-repeat,no-repeat;
    } @else if $type == $TypeSize {
      @return 200px, 200px, 200px,
      $fenceSize,$fenceSize,$fenceSize,$fenceSize,$fenceSize,$fenceSize,$fenceSize,
      $trainTreeType1Size,$trainTreeType2Size,$trainTreeType1Size,$trainTreeType2Size,$trainTreeType1Size,$trainTreeType2Size,$trainTreeType1Size,
      $streetLightSize,$streetLightSize,
      $railwaySignSize,$railwaySignSize;
    }
  }
  .backgroundImages {
    background-position: loadImages($TypePosition);
    background-size: loadImages($TypeSize);
  }
  :global(.block) {
    height: 70px;
  }
}

.abovePlatformContainer.mobileMode {
  :global(.block) {
    height: 80px;
  }
}
