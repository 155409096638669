@import '../../../../assets/variable';

$size: 300px;
.firecrackersContainer {
  position: absolute;
  z-index: 0;
  .firecracker {
    border: 12px dotted white;
    border-radius: 50%;
    padding: 20px;
  }
  .firecrackers {
    width: $size;
    height: $size;
    position: absolute;
    @extend .firecracker;
    div {
      width: 100%;
      height: 100%;
      @extend .firecracker;
    }
    transform: scale(0);
    opacity: 0;
    animation: scaleUp 0.7s ease-out 0s;
    @keyframes scaleUp {
      60% {
        transform: scale(1);
        opacity: 1;
      } 100% {
        transform: scale(0.9);
        opacity: 0;
      }
    }
  }
}

.firecrackersContainer.tabletMode {
  $size: 250px;
  .firecracker{
    padding: 20px;
    border-width: 10px;
  }
  .firecrackers {
    width: $size;
    height: $size;
  }
}

.firecrackersContainer.mobileMode {
  $size: 200px;
  .firecracker{
    padding: 15px;
    border-width: 10px;
  }
  .firecrackers {
    width: $size;
    height: $size;
  }
}
