@import '../../../assets/variable';

.skyContainer {
  transition: background-color 5s;
  .stars {
    position: absolute;
    height: 100%;
    width: 100%;
    &.starAnim > .star {
      animation: fade 2s ease-in-out 0s infinite alternate;
      @keyframes fade {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }
    .star {
      width: 1px;
      height: 1px;
      border-radius: 50%;
      background-color: white;
      position: absolute;
      opacity: 0;
    }
  }
}

.skyContainer.tabletMode {
}

.skyContainer.mobileMode {
}
