@import '../../assets/variable';

.interactiveResumeContainer {
  height: 100%;
  overflow: hidden;
  background-color: $gray0;
  .flexContainer {
    .topBox,
    .bottomBox {
      position: relative;
      // overflow: hidden;
    }
    .topBox {
    }
    .bottomBox {
    }
  }
  .sideBarMenuIcon, .shareButtonIcon .SVGIcon {
    fill: white;
    background-color: rgba($color: black, $alpha: 0.1);
    &:hover {
      background-color: rgba($color: black, $alpha: 0.3);
    }
  }
  
  .topRightLinks {
    position: fixed;
    right: 0;
    top: 0;
    margin: 10px;
    z-index: 1;
    .SVGIcon {
      width: 35px;
      height: 35px;
      fill: white;
      background-color: rgba($color: black, $alpha: 0.1);
      &:hover {
        background-color: rgba($color: black, $alpha: 0.3);
      }
    }
    .homeIconLink {
      display: block;
      text-decoration: none;
      font-size: 0;
      @extend .darkModeFocus;
    }
    .shareButtonIcon {
      margin-top: 10px;
    }
  }
}

.interactiveResumeContainer.tabletMode {
}

.interactiveResumeContainer.mobileMode {
}
