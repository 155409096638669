@import '../../assets/variable';

.micContainer {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  .mic, .speaker {
    fill: white;
    width: 25px;
    height: 40px;
    position: absolute;
    &.active {
      animation: shake 0.5s ease-in-out infinite;
    }
  }
  .micCircle {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.4);
    background-color: $gray10;
    &.active {
      background-color: $red5;
    }
    &.aiAnswering {
      background-color: $cyan10;
    }
  }
  .micLevel {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba($color: $gray10, $alpha: 0.4);
    transition: transform 0.15s ease-in-out;
  }
  .micPulse {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid rgba($color: $gray10, $alpha: 0.3);
    &.active {
      animation: pulse 1.3s infinite;
    }
    @keyframes pulse {
      0% {
        transform: scale(1);
        opacity: 1;
      }
      100% {
        transform: scale(2);
        opacity: 0;
      }
    }
  }
  .micLoader {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top: 3px solid $red5;
    border-bottom: 3px solid $red5;
    animation: rotate 1s linear infinite;
    z-index: 1;
    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.8;
  }
}

.micContainer.desktopMode {
  &:hover {
    .micCircle:not(.active):not(.aiAnswering):not(.loading) {
      box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.4);
      background-color: $red5;
    }
  }
}
