@import '../../../assets/variable';

.professionalSkillsContainer {
  padding: 40px $desktopPaddingLeftRight 20px $desktopPaddingLeftRight;
  .skillsContainer {
    & + .skillsContainer {
      margin-top: 10px;
    }
    margin-left: 10px;

    .skillContainer {
      margin-top: 5px;
    }
  }
}

.professionalSkillsContainer.tabletMode {
  padding: 40px $tabletPaddingLeftRight 20px $tabletPaddingLeftRight;
}

.professionalSkillsContainer.mobileMode {
  padding: 20px $mobilePaddingLeftRight 20px $mobilePaddingLeftRight;
  .skillsContainer {
    & + .skillsContainer {
      margin-top: 20px;
    }
    .skillContainer {
      margin-top: -5px;
    }
  }
}
