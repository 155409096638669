@import '../../../assets/variable';

.employmentHistoryContainer {
  padding: 20px $desktopPaddingLeftRight 20px $desktopPaddingLeftRight;
  .employmentHistoryCard,
  .tableFormat {
    @extend .colorTransition;
  }
  :global {
    .lightBg {
      background-color: $gray0;
    }
    .tableHeader {
      font-weight: 500;
      background-color: $cynan60;
      color: white;
    }
    .flex-row {
      .size-1 {
        max-width: 50px;
        text-align: center;
      }
      div {
        padding: 5px;
      }
    }
  }
}

.employmentHistoryContainer.tabletMode {
  padding: 20px $tabletPaddingLeftRight 20px $tabletPaddingLeftRight;
}

.employmentHistoryContainer.mobileMode {
  padding: 10px $mobilePaddingLeftRight 20px $mobilePaddingLeftRight;
  .employmentHistoryCard {
    margin-top: 5px;
    padding: 10px 5px 10px 5px;
    border-radius: 4px;
    p {
      font-size: 15px;
    }
    :global {
      .tableHeader {
        min-width: 120px;
        background-color: transparent;
        color: initial;
      }
    }
  }
}

[data-theme='dark'] {
  .employmentHistoryContainer {
    :global {
      .lightBg {
        background-color: transparent !important;
      }
      .tableHeader {
        background-color: $gray35 !important;
      }
    }
    div p {
      color: $gray5 !important;
    }
    &.mobileMode {
      :global {
        .lightBg {
          background-color: $gray35 !important;
        }
        .tableHeader {
          background-color: transparent !important;
        }
      }
    }
  }
}
