@import '../../assets/variable';

$menuIconContainerSize: 35px;
.sideNavMenuIconContainer {
  width: $menuIconContainerSize;
  height: $menuIconContainerSize;
  position: absolute;
  z-index: 100;
  margin: 10px;
  & > .iconButton {
    .SVGIcon {
      width: $menuIconContainerSize;
      height: $menuIconContainerSize;
      cursor: pointer;
    }
  }
}