@import '../../../assets/variable';

.educationContainer {
  padding: 20px $desktopPaddingLeftRight 20px $desktopPaddingLeftRight;
  color: $gray30;
  .exam,
  .result {
    padding: 5px 10px 5px 10px;
  }
  .result {
    text-align: right;
    min-width: 100px;
  }
  .getEducatioinList {
    @extend .colorTransition;
  }
  :global {
    .lightBg {
      background-color: $gray0;
    }
    .flex-row {
      align-items: center;
    }
  }
}

.educationContainer.tabletMode {
  padding: 20px $tabletPaddingLeftRight 20px $tabletPaddingLeftRight;
}

.educationContainer.mobileMode {
  padding: 10px $mobilePaddingLeftRight 20px $mobilePaddingLeftRight;
  .getEducatioinList {
    padding: 5px;
    p {
      font-size: 15px;
    }
  }
  :global {
    .lightBg {
      border-radius: 4px;
    }
  }
}

[data-theme='dark'] {
  .educationContainer {
    :global(.lightBg) {
      background-color: $gray35 !important;
    }
    .exam,
    .result {
      p {
        color: $gray5 !important;
      }
    }
  }
}
