@import '../../../assets/variable';

$cardLeftSpace: 32px;
.projectsContainer {
  padding: 20px $desktopPaddingLeftRight 20px $desktopPaddingLeftRight;
  color: $gray30;
  .projectCardContainer {

    @include timelineCard($cynan60);
    ul {
      margin: 0;
    }
    .period {
      font-size: 14px;
      font-weight: 500;
    }
    :global {
      .resumeHeading2 {
        color: $gray30;
      }
      .fadeInAnimation:first-of-type > div {
        margin-top: 0;
      }
    }
  }
}

.projectsContainer.tabletMode {
  padding: 20px $tabletPaddingLeftRight 20px $tabletPaddingLeftRight;
  
}

.projectsContainer.mobileMode {
  padding: 10px $mobilePaddingLeftRight 20px $mobilePaddingLeftRight;
  .projectCardContainer {
    @include timelineCard($cynan60, 20px, 20px, 20px);
    padding-top: 0px;
    padding-bottom: 0px;
    border-left-width: 1px;
    .card {
      border: 1px solid $cynan60;
      &::after {
        height: 1px;
      }
      &::before {
        border-width: 1px;
        margin-left: -8px !important;
      }
      & > ul {
        padding-left: 15px;
      }
      & p {
        font-size: 14px;
      }
      :global {
        .resumeHeading2 {
          font-size: 16px;
        }
      }
    }
  }
}

[data-theme='dark'] {
  .projectCardContainer {
    border-left-color: $gray35 !important;
    .card{
      border-color: $gray35 !important;
      border-left-width: 1px !important;
      &::after,&::before {
        background-color: $gray35 !important;
        border-color: $gray35 !important;
      }
      &::before {
        background-color: $gray40 !important;
        margin-left: -9px !important;
      }
    }
    p,a, li {
      color: $gray5 !important;
    }
  }
}
