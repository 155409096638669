@import '../../../assets/variable';

.certificationsContainer {
  padding: 20px $desktopPaddingLeftRight 20px $desktopPaddingLeftRight;
  color: $gray30;
  ul {
    margin: 0;
  }
}

.certificationsContainer.tabletMode {
  padding: 20px $tabletPaddingLeftRight 20px $tabletPaddingLeftRight;
}

.certificationsContainer.mobileMode {
  padding: 20px $mobilePaddingLeftRight 20px $mobilePaddingLeftRight;
  p {
    font-size: 15px;
  }
}

[data-theme='dark'] {
  .certificationsContainer {
    ul li {
      color: $gray5 !important;
    }
  }
}
