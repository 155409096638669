@import '../../../../assets/variable';

.boatContainer {
  width: 343px;
  height: 252px;
  background-repeat: no-repeat;
  background-size: cover;
  animation: boatWaveEffect 1s linear 0s infinite alternate;
  z-index: 2;
}

.boatContainer.tabletMode {
  &.enableScale {
    transform: scale(0.7);
    &.rotateBack {
      transform: scale(0.7) rotateY(180deg);
    }
    bottom: -39px !important;
    &.positionLeft {
      margin-left: -52px;
    }
    &.positionRight {
      margin-left: 50px;
    }
  }
}

.boatContainer.mobileMode {
  &.enableScale {
    transform: scale(0.8);
    &.rotateBack {
      transform: scale(0.8) rotateY(180deg);
    }
    bottom: -26px !important;
    &.positionLeft {
      margin-left: -34px;
    }
    &.positionRight {
      margin-left: 32px;
    }
  }
}

@keyframes boatWaveEffect {
  0% {
    margin-bottom: -10px;
  }
  100% {
    margin-bottom: -15px;
  }
}
