@import '../../../../assets/variable';

.lighthouseContainer {
  width: 80px;
  height: 300px;
  position: absolute;
  bottom: 0;
  .noBullet {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .pole {
    height: 80%;
    width: 100%;
    background: white;
    position: relative;
    clip-path: polygon(30% 0%, 70% 0%, 90% 100%, 10% 100%);
    overflow: hidden;
    .redStrips {
      position: absolute;
      z-index: 2;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      li {
        height: 30px;
        width: 100%;
        background-color: $orange0;
        transform: rotate(-23deg);
      }
    }
    &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 35%;
      z-index: 3;
    }
  }
  .lightBox {
    align-items: center;
    justify-content: flex-end;
    .dome {
      width: 50%;
      max-height: 20px;
      min-height: 15px;
      background-color: $red0;
      border-top-right-radius: 60%;
      border-top-left-radius: 60%;
      position: relative;
      &::after {
        position: absolute;
        content: '';
        width: 7px;
        height: 16px;
        background-color: $red0;
        top: -7px;
        border-radius: 5px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .bottom,
    .top {
      background: $orange0;
      max-height: 10px;
      width: 50%;
      position: relative;
      &::before {
        position: absolute;
        content: '';
        border-right: 10px $orange0 solid;
        border-left: 0px transparent solid;
        border-bottom: 10px transparent solid;
        left: -10px;
      }
      &::after {
        position: absolute;
        content: '';
        border-left: 10px $orange0 solid;
        border-right: 0px transparent solid;
        border-bottom: 10px transparent solid;
        right: -10px;
      }
    }
    .middle {
      width: calc(50% + 20px);
      max-height: 40px;
      position: relative;
      .lightContainer {
        width: 60%;
        height: 100%;
        background-color: white;
        position: absolute;
        bottom: 0px;
        z-index: 0;
        left: 50%;
        transform: translateX(-50%);
        justify-content: center;
        align-items: center;
        .lightBall {
          width: 23px;
          height: 23px;
          background-color: $yellow0;
          border-radius: 50%;
          .lightLeft,.lightRight {
            height: 150px;
            width: 400px;
            background: linear-gradient(
              to right,
              rgba(247, 168, 60, 0) 0%,
              rgba(247, 168, 60, 0.5) 100%
            );
            transform: translate(-100%, -49%);
            clip-path: polygon(0 0, 100% 52%, 100% 61%, 0 100%);
            position: relative;
            margin-left: 5px;
            animation: anim-light 6s linear infinite alternate;
            @keyframes anim-light {
              from {
                clip-path: polygon(0 0, 100% 52%, 100% 61%, 0 100%);
              }
              to {
                clip-path: polygon(0 55%, 100% 55%, 100% 58%, 0 55%);
              }
            }
          }
          .lightRight {
            transform: translate(3%, -136%) rotate(180deg);
          }
        }
      }
      .line {
        width: 100%;
        height: 3px;
        background-color: $orange0;
        position: absolute;
        bottom: 9px;
      }
      .balcony {
        justify-content: space-between;
        align-items: flex-end;
        position: relative;
        li {
          background-color: $orange0;
          width: 3px;
          height: 11px;
          display: inline-block;
        }
      }
    }
  }
}

.lighthouseContainer.tabletMode {
  height: 200px;
  width: 60px;
  .redStrips {
    li {
      height: 20px;
    }
  }
}

.lighthouseContainer.mobileMode {
}
