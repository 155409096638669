@import '../../../../assets/variable';

.trainContainer {
  height: 157px;
  width: 338px;
  background-repeat: no-repeat;
  margin-bottom: -2px;
}

.trainContainer.tabletMode {
  &.enableScale {
    transform: scale(0.7);
    &.rotateBack {
      transform: scale(0.7) rotateY(180deg);
    }
    margin-bottom: -24px;
    &.positionLeft {
      margin-left: -51px;
    }
    &.positionRight {
      margin-left: 50px;
    }
  }
}

.trainContainer.mobileMode {
  &.enableScale {
    transform: scale(0.8);
    &.rotateBack {
      transform: scale(0.8) rotateY(180deg);
    }
    margin-bottom: -17px;
    &.positionLeft {
      margin-left: -34px;
    }
    &.positionRight {
      margin-left: 32px;
    }
  }
}

.runTrain {
  animation-name: runTrainAnim;
  animation-duration: 0.4s;
  animation-iteration-count: infinite;
  animation-timing-function: steps(4);
}
@keyframes runTrainAnim {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: -1343px;
  }
}
