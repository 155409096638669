@import '../../../../assets/variable';

.collegeScoreCardContainer {
  position: absolute;
  bottom: -150%;
  width: 1000px;
  height: 350px;
  padding: 10px 40px 30px 40px;
  background: #0000000f;
  border-radius: 2px;
  left: 400px;
  .scoreCardLabelsContainer {
    .title {
      h1 {
        font-size: 22px;
        text-align: center;
        color: white;
      }
      max-height: 40px;
    }
    .semesterListContainer {
      width: 100%;
      max-height: 40px;
      .semesterList {
        list-style: none;
        padding: 0;
        color: white;
        font-size: 15px;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        li:first-child {
          font-size: 10px;
        }
      }
    }
  }

  .scoreCardContainer {
    position: relative;
    .scoreCardLabelContainer {
      justify-content: space-between;
      position: absolute;
      width: 100%;
      height: 100%;
      div {
        color: white;
        border-bottom: 1px solid #ffffff57;
        padding-bottom: 5px;
        font-size: 20px;
      }
    }
    .scoreCardSVGContainer {
      position: absolute;
      width: 100%;
      height: 100%;
      .svgContainer {
        width: 100%;
        height: 100%;
        .dashOffset {
          stroke-dashoffset: 1000;
          stroke-dasharray: 1000;
        }
        .marksSGPAPath {
          fill: none;
          stroke: #a81c1c;
          stroke-width: 3;
          @extend .dashOffset;
        }
        .marksCGPAPath {
          fill: none;
          stroke: #dbcd94;
          stroke-width: 2;
          @extend .dashOffset;
        }
        .drawPathAnim {
          animation: drawPathAnim 3s linear 0.4s forwards;
        }
        @keyframes drawPathAnim {
          to {
            stroke-dashoffset: 0;
          }
        }
        .lowOpacity2 {
          opacity: 0.2;
        }
        .sgpaText,
        .cgpaText {
          font-size: 11px;
          font-weight: 600;
          fill: white;
          @extend .lowOpacity2;
        }
        .sgpaCircles,
        .cgpaCircles {
          stroke: #a81c1c;
          fill: white;
          stroke-width: 3;
          @extend .lowOpacity2;
        }
        .cgpaCircles {
          stroke: #dbcd94;
        }
        .fadeIn {
          animation: fadeInAnim 1s linear 0.4s forwards;
        }
        @keyframes fadeInAnim {
          to {
            opacity: 1;
          }
        }
      }
    }
  }
}

.showScoreCardAnim {
  animation: moveFromBottom 0.4s ease-in-out 0s 1 forwards;
}

@keyframes moveFromBottom {
  95% {
    bottom: 10%;
  }
  100% {
    bottom: 0;
  }
}

.collegeScoreCardContainer.tabletMode {
  transform: scale(0.6);
  margin-bottom: -70px;
  left: 100px;
}

.collegeScoreCardContainer.mobileMode {
  transform: scale(0.8);
  margin-bottom: -40px;
  left: 200px;
}
