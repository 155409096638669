@import '../../../assets/variable';

.aboutMe {
  padding: 70px $desktopPaddingLeftRight 40px $desktopPaddingLeftRight;
  align-items: center;
  .aboutMeHeading {
    padding-right: 20px;
    .aboutMeParagraph {
      padding-bottom: 16px;
      color: $gray30;
    }
  }
  .contactInfo {
    padding-left: 40px;
    div {
      padding: 5px 0 5px 0;
      min-width: 70px;
    }
  }
}

.aboutMe.tabletMode {
  padding: 60px $tabletPaddingLeftRight 40px $tabletPaddingLeftRight;
  .aboutMeHeading {
    padding-right: 10px;
  }
  .contactInfo {
    padding-left: 10px;
  }
}

.aboutMe.mobileMode {
  padding: 30px $mobilePaddingLeftRight 20px $mobilePaddingLeftRight;
  font-size: 15px;
  align-items: flex-start;
  .aboutMeHeading {
    padding: 0px;
    .aboutMeParagraph {
      margin-top: -10px;
    }
  }
  .contactInfo {
    padding: 0px;
  }
}

[data-theme='dark'] {
  .aboutMe {
    .aboutMeParagraph,
    .contactInfo div p {
      color: $gray5 !important;
    }
  }
}
