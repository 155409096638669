@import '../../../assets/variable';

.header {
  background-color: $cyan10;
  background-image: $svg_line;
  display: flex;
  align-items: center;
  padding: 20px $desktopPaddingLeftRight 0 $desktopPaddingLeftRight;
  @extend .colorTransition;
  .headerLeftContainer {
    flex: 1;
    max-width: 240px;
    .profilePhoto {
      height: 218px;
      width: 170px;
      background-repeat: no-repeat;
      background-position: center top;
      background-size: 112%;
      border: 3px solid white;
      position: relative;
      top: 30px;
      transition: top 0.3s;
      border-radius: 3px;
      box-shadow: $box-shadow;
      background-color: white;
      filter: grayscale(20%);
      &:hover {
        top: 25px;
      }
    }
  }
  .headerRightContainer {
    flex: 1;
    h1,
    p {
      padding: 0;
      margin: 0;
    }
    .candidateName {
      color: white;
      font-size: 50px;
      font-weight: 400;
    }
    .profileName, .skill {
      color: white;
      font-size: 18px;
      margin-left: 5px;
    }
    .skill {
      font-size: 12px;
    }
    .btnContainer {
      margin-top: 15px;
      .downloadCVBtn {
        @include button();
      }
      .interativeBtn {
        @include button(white, $blue10, $blue20);
        margin-left: 15px;
      }
    }
  }
}

.header.tabletMode {
  padding: 20px $tabletPaddingLeftRight 0 $tabletPaddingLeftRight;
  .headerLeftContainer {
    max-width: 200px;
  }
  .headerRightContainer {
    .btnContainer {
      .downloadCVBtn,
      .interativeBtn {
        padding: 8px 15px 8px 15px;
      }
    }
  }
}

.header.mobileMode {
  flex-direction: column;
  padding: 40px $mobilePaddingLeftRight 40px $mobilePaddingLeftRight;
  .headerLeftContainer .profilePhoto {
    top: 0;
    &:hover {
      top: -10px;
    }
  }
  .headerRightContainer {
    margin-top: 10px;
    text-align: center;
    .candidateName {
      font-size: 35px;
    }
    .profileName {
      font-size: 16px;
      margin-left: 0;
    }
    .skill {
      font-size: 11px;
      margin-left: 0;
    }
    .btnContainer {
      margin-top: 0;
      .downloadCVBtn,.interativeBtn {
        margin: 10px 5px 0px 5px;
      }
    }
  }
}

[data-theme='dark'] {
  .header {
    background-color: $cyan50;
  }
}
