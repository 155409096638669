@import '../../../../assets/variable';

.boardContainer {
  width: 100%;
  height: 100%;
  position: relative;

  .children {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    & > .child {
      padding: 5px 10px 5px 10px;
    }
  }
  .verticalPole {
    content: '';
    position: absolute;
    height: 100%;
    width: 40px;
    background-color: #063739;
    transform: translateX(-50%);
    left: 50%;
    z-index: 0;
    border-top-right-radius: 7px;
    overflow: hidden;
    border-top-left-radius: 7px;
  }
  .borderRadius {
    border-radius: 2px;
  }
}

.boardContainer.tabletMode {
}

.boardContainer.mobileMode {
}
