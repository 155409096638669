@import '../../assets/variable';

.aiAssistantContainer {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  .transcriptContainer {
    position: absolute;
    bottom: 130%; /* Position above button */
    left: 50%;
    transform: translateX(-50%);
    background-color: $gray0;
    color: black;
    padding: 6px 12px;
    border-radius: 5px;
    font-size: 14px;
    min-width: 200px; /* Set max width */
    text-align: center;
    word-wrap: break-word;
    white-space: normal; /* Allow line breaks */
    overflow-wrap: break-word;
    transition: opacity 0.3s ease-in-out;
    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: $gray0 transparent transparent transparent;
    }
    &.speaking {
      background-color: $cyan10;
      color: white;
      &::after {
        border-color: $cyan10 transparent transparent transparent;
      }
    }
    &.loadingAnswer {
      background-color: $blue10;
      color: white;
      &::after {
        border-color: $blue10 transparent transparent transparent;
      }
    }
  }
}
