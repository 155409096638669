@import '../../../../../assets/variable';

$tvWidth: 290px;

.tvContainer {
  position: absolute;
  left: 500px;
  width: $tvWidth;
  height: 80%;
  align-items: center;
  top: -100%;
  margin-top: -5px;
  transform-origin: center top;
  &.moveDown {
    animation: moveDown 1s ease-out 0s forwards,
    swing2Deg 2s ease-in-out 0s infinite alternate;
    @keyframes moveDown {
      to {
        top: 0;
      }
    }
  }
  .chain {
    width: 25px;
    border-left: 2px solid $gray40;
    border-right: 2px solid $gray40;
    position: relative;

    & > p {
      transform: rotateZ(-90deg);
      position: absolute;
      top: 100px;
      white-space: nowrap;
      transform-origin: left top;
      background-color: $gray30;
      color: $gray0;
      padding: 0px 10px 0px 10px;
      height: 21px;
    }
  }
  .tvBody {
    height: 155px;
    width: 100%;
    background-color: $gray40;
    border: 10px solid $gray30;
    border-radius: 4px;
    text-align: left;
    box-shadow: inset 0px 0px 3px 0px black;
    .code {
      color: $yellow10;
      font-size: 14px;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      .pointer {
        animation: blink 0.5s ease-in-out 0s infinite;
        @keyframes blink {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      }
    }
  }
}

$tvWidthTablet: 290px;
.tvContainer.tabletMode {
  height: 90%;
  width: $tvWidthTablet;
  .chain {
    & > p {
      display: none;
    }
  }
  .tvBody {
    .code {
      font-size: 13px;
    }
  }
}

.tvContainer.mobileMode {
  width: $tvWidthTablet;
  .tvBody {
    .code {
      font-size: 13px;
    }
  }
}
