@import '../../assets/variable';

$drawerMinSize: 250px;
.drawerContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  left: 0;
  top: 0;
  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: background-color 0.1s;
  }
  .drawerContentContainer {
    position: absolute;
    background: white;
    overflow: auto;
    transition: transform 0.2s;
  }
  &.directionLeft {
    .drawerContentContainer {
      left: 0;
      top: 0;
      width: $drawerMinSize;
      height: 100%;
      box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
      0px 16px 24px 2px rgba(0, 0, 0, 0.14),
      0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    }
  }
  &.directionBottom {
    .drawerContentContainer {
      left: 0;
      bottom: 0;
      width: 100%;
      min-height: 10px;
      box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
        0px 16px 24px 2px rgba(0, 0, 0, 0.14),
        0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    }
  }
  &.open {
    .background {
      background-color: rgba(0, 0, 0, 0.5);
    }
    .drawerContentContainer {
      transform: none
    }
  }
  &.close {
    .background {
      background-color: transparent;
    }
    animation: setVisibility 0.2s linear 0s forwards;
    @keyframes setVisibility {
      to {
        visibility: hidden;
      }
    }
  }
  &.close.directionLeft {
    .drawerContentContainer {
      transform: translateX(-100%);
    }
  }
  &.close.directionBottom {
    .drawerContentContainer {
      transform: translateY(100%);
    }
  }
}

.drawerContainer.isDark {
  .drawerContentContainer {
    background-color: $gray31;
  }
}
