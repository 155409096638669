@import '../../../assets/variable';

.birdsContainer {
  .bird {
    height: 72px;
    width: 99px;
    background-repeat: no-repeat;
    margin: 17px;
    background-position-y: -2px;
    position: absolute;
    left: -100px;
    animation: flyAnim .4s steps(4) infinite,birdFlyRightAnim 200s linear infinite;
  }
  .bird_1 {
    transform: scale(0.2);
    animation: flyAnim .6s steps(4) infinite,birdFlyRightAnim 100s linear infinite;
  }
  .bird_2 {
    transform: scale(0.3);
    animation: flyAnim .5s steps(4) infinite,birdFlyRightAnim 70s 5s linear infinite;
    top: 20%;
  }
  .bird_3 {
    transform: scale(0.4);
    animation: flyAnim .4s steps(4) infinite,birdFlyRightAnim 50s  8s linear infinite;
    top: 40%;
  }
  .bird_4 {
    transform: scale(0.5);
    animation: flyAnim .4s steps(4) infinite,birdFlyRightAnim 30s 1s linear infinite;
    top: 60%;
  }
}

.birdsContainer.tabletMode {
}

.birdsContainer.mobileMode {
}

@keyframes flyAnim {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: -400px;
  }
}

@keyframes birdFlyRightAnim {
  to {
     left: 110%;
  }
}
