@import '../../../assets/variable';

.personOnBoatContainer {
    position: absolute;
    bottom: 0px;
}


.personOnBoatContainer.tabletMode {
    transform: scale(0.7);
    margin-bottom: -14px !important;
}

.personOnBoatContainer.mobileMode {
    transform: scale(0.8);
    margin-bottom: -9px !important;
}